import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
// const socket = io("http://localhost:3031/"); //local
const socket = io('https://api.kbms.my/');
// const socket = io("https://api-sit.kbms.my/");

const client = feathers();
// client.configure(socketio(socket,{  timeout: 10000, pingTimeout: 60000}));
client.configure(
  socketio(socket, {
    timeout: 50000,
    pingTimeout: 60000,
    transports: ['websocket'],
    forceNew: true,
    upgrade: false,
  })
);
client.configure(
  authentication({
    storage: window.localStorage,
  })
);

export default client;

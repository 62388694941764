import React, { Component } from 'react';
import {
  DatePicker,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Select,
  Col,
  Row,
  Card,
  message,
  Spin,
  Tabs
} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentFormItems from "./form-payment-items";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems
} from '../../actions/actions-invoiceitems';

import '../../App.css';
import moment from 'moment';
import { selectedRole } from '../../actions/actions-commons';


const { TabPane } = Tabs;
const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const FormItem = Form.Item;
const EditableContext = React.createContext();
const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const formItemLayout2 = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 16
  }
};
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MYR',
  minimumFractionDigits: 2
})
const EditableRow = ({
  form,
  index,
  ...props
}) => (<EditableContext.Provider value={form}>
  <tr {...props} />
</EditableContext.Provider>);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;

class EditableCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      charges: []
    }
  }

  componentDidUpdate() {
    // console.log('EditableCell', 'didupdate', this.props);
    if (this.props.charges !== this.state.charges) {
      this.setState({ charges: this.props.charges });
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({
      editing
    }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = () => {
    const { record, handleSave } = this.props;
    let newRecord = {};
    this.form.validateFields((error, values) => {
      if (values.chargeId) {
        const newData = [...this.props.recurringItems];
        const index = newData.findIndex(item => values.chargeId === item.chargeId._id);
        const item = newData[index];
        newRecord = {
          ...record,
          actualAmount: item.amount
        };
      } else {
        newRecord = record;
      }

      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({
        ...newRecord,
        ...values
      });
    });
  }

  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />;
    } else if (this.props.inputType === 'month') { //monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return <Select ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} placeholder="Please Select Month" style={{
        width: '100%'
      }}>
        <Option value="January">January</Option>
        <Option value="February">February</Option>
        <Option value="March">March</Option>
        <Option value="April">April</Option>
        <Option value="May">May</Option>
        <Option value="June">June</Option>
        <Option value="July">July</Option>
        <Option value="August">August</Option>
        <Option value="September">September</Option>
        <Option value="October">October</Option>
        <Option value="November">November</Option>
        <Option value="December">December</Option>
      </Select>
    } else if (this.props.inputType === 'chargeId') {
      return (
        <Select ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} placeholder="Select Fee" style={{ width: '100%' }}>
          {
            this.props.recurringItems.map(dropdown => (<Option value={dropdown.chargeId._id}>
              {dropdown.chargeId.code}
            </Option>))
          }
        </Select>
      )
    } else {
      return <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />;
    }
  };

  render() {
    const { editing } = this.state;

    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;

    return (<td ref={node => (this.cell = node)} {...restProps}>
      {
        editable
          ? (<EditableContext.Consumer>
            {
              (form) => {
                this.form = form;
                return (
                  editing
                    ? (<FormItem style={{
                      margin: 0
                    }}>
                      {
                        form.getFieldDecorator(dataIndex, {
                          rules: [
                            {
                              required: true,
                              message: `${title} is required.`
                            }
                          ],
                          initialValue: record[dataIndex]
                        })(this.getInput())
                      }
                    </FormItem>)
                    : (<div className="editable-cell-value-wrap" style={{
                      paddingRight: 24,
                      border: '1px solid darkgray',
                      backgroundColor: 'white'
                    }} onClick={this.toggleEdit}>
                      {restProps.children}
                    </div>));
              }
            }
          </EditableContext.Consumer>)
          : restProps.children
      }
    </td>);
  }
}

class IndexKnockOffByPropertyunit extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Doc Id',
        dataIndex: 'docId',
        key: '',
        width: '15%'
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: '',
        width: '15%'
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: '',
        width: '10%'
      }, {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: '',
        width: '15%',
        render: (text, record) => (<span>
          {
            record.dueDate
              ? this.convertDate(record.dueDate)
              : ''
          }
        </span>)
      }, {
        title: 'Charge Amount',
        dataIndex: 'appliedAmount',
        width: '15%',
        key: '',
        render: (text, record) => (<span style={{
          float: 'right'
        }}>
          {this.formatAmt(record.appliedAmount)}
        </span>)
      }, {
        title: 'Remaining Charges',
        dataIndex: 'balance',
        width: '15%',
        key: '',
        //editable: true,
        render: (text, record) => (<span style={{
          float: 'right'
        }}>
          {this.formatAmt(record.appliedAmount - record.knockOffBalance)}
        </span>)
      }, {
        title: 'KnockOff Amount',
        dataIndex: 'knockOffAmount',
        width: '15%',
        key: '',
        editable: true,
        render: (number, record) => (<span >
          {this.formatAmt(record.knockOffAmount)}
        </span>)
      }
    ];

    this.columnsPrepayment = [
      {
        title: ' ',
        dataIndex: 'operation',
        width: '5%',
        key: '0s',
        render: (text, record) => (
          this.state.dataSourcePrepayment.length >= 1
            ? (<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
              <a href="javascript:;">x</a>
            </Popconfirm>)
            : null)
      },
      {
        title: 'Charges',
        dataIndex: 'chargeId',
        key: '1',
        width: '25%',
        editable: true,
        render: (text, record) => (<Select value={record.chargeId} style={{
          width: '100%'
        }}>
          {
            this.state.recurringItems.map(dropdown => (<Option value={dropdown.chargeId._id}>
              {dropdown.chargeId.code}
            </Option>))
          }
        </Select>)
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: '2',
        width: '20%',
        editable: true
      },
      {
        title: 'Next Process Month',
        dataIndex: 'month',
        key: '3',
        width: '20%',
        editable: true
      },
      {
        title: 'Charge Amount',
        dataIndex: 'actualAmount',
        key: '4',
        width: '15%',
        editable: false,
        render: (text, record) => (<span>
          {this.formatAmt(record.actualAmount)}
        </span>)
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: '5',
        width: '15%',
        editable: true
      }
    ];

    this.state = {
      prepaymentTotalAmount: 0.00,
      prepaymentTotalAmountDisplay: 0.00,
      prepaymentSelectedAmount: 0.00,
      selectedAmount: 0.00,
      totalAmount: 0.00,
      payAmount: 0.00,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      dataSourcePrepayment: [],
      selectedRowKeys: [],
      count: 0,
      charges: [],
      recurringItems: [],
      selectedRows: [],
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
      prepaymentUA: 0.00,
      selectPrepaymentChargeId: '',
      prepayAmount: 0,
      prepaymentHist: [],
      recurrMonths: [],
      creditNotes: []
    }

  }

  componentDidUpdate() {

    if (this.state.selectedRows.length > 0) {

      let tempAmt = 0;
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        tempAmt += parseFloat(this.state.selectedRows[i].knockOffAmount);
      }

      if (tempAmt !== this.state.totalAmount) {

        this.setState({ totalAmount: tempAmt });
      }

    }

    let payAmount = +(this.state.payAmount || 0)
    let totalAmount = +(this.state.totalAmount || 0)
    let prepaymentTotalAmountDisplay = +(this.state.prepaymentTotalAmountDisplay || 0)
    let prepaymentUA = +(this.state.prepaymentUA || 0)

    if (payAmount !== (totalAmount + prepaymentTotalAmountDisplay + prepaymentUA)) {

      this.setState({
        totalAmount: totalAmount,
        prepaymentTotalAmountDisplay: prepaymentTotalAmountDisplay,
        prepaymentUA: prepaymentUA,
        payAmount: totalAmount + prepaymentTotalAmountDisplay + prepaymentUA
      });

      this.props.form.setFieldsValue({
        payAmount: parseFloat(this.state.totalAmount + this.state.prepaymentTotalAmountDisplay + this.state.prepaymentUA).toFixed(2)
      })
    }

  }

  componentDidMount() { }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    this.setState({ loading: true });
    client.authenticate().then((res) => {

      client.service('invoices').find({
        query: {
          propertyId: companyId,
          propertyunitId: this.props.match.params.id,
          status: {
            $in: ['ISSUED', 'PARTIAL']
          }

        }

      }).then((invoicesRes) => {
        let invoiceList = []
        _.forEach(invoicesRes.data, function (a) {

          invoiceList.push(a.invoiceNo)

        })
      })

      //
      return client.service('invoice-items').find({
        query: {
          $populate: 'propertyId invoiceId',
          propertyId: companyId,
          propertyunitId: this.props.match.params.id,
          status: {
            $in: ['ISSUED', 'PARTIAL']
          },
          $sort: {
            docId: 1
          }
        }
      })
    })
      .then((res) => {
       
        this.setState({ dataSource: res.data, loading: false });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    this.getCharges();
    this.getRecurringItem();
  }

  getCharges() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {

        return client.service('charges').find({
          query: {
            propertyId: companyId
          }
        })
      })
      .then((res) => {
        this.setState({ charges: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  getRecurringItem() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('recurring-items').find({
          query: {
            propertyId: companyId,
            propertyunitId: this.props.match.params.id,
            $populate: 'chargeId'
          }
        })
      })
      .then((res) => {
        this.setState({ recurringItems: res.data });

        let chargeIdArr = _.map(res.data, (i) => {
          return i.chargeId._id
        })

        return client.service('prepayments').find({
          query: {
            propertyId: companyId,
            propertyunitId: this.props.match.params.id,
            chargeId: { $in: chargeIdArr }
          }
        })
      })
      .then((res) => {
        this.setState({ prepaymentHist: res.data })
      })
      .catch((err) => {
        console.log(err);
      });

  }

  checkedAllCheckedBox() {

    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {

      this.state.dataSource[i].knockOffAmount = Number(this.state.dataSource[i].appliedAmount - this.state.dataSource[i].knockOffBalance).toFixed(2);
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt = parseFloat(totalSelectedAmt + this.state.dataSource[i].knockOffAmount).toFixed(2);
      totalKnockOffAmt += this.state.dataSource[i].knockOffAmount;
    }


    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        totalAmount: parseFloat(totalSelectedAmt + this.state.prepaymentTotalAmount).toFixed(2)
      });
    }, 1000);

  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0
      ? integer.toFixed(2)
      : 0.00;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }

  handleDelete = (key) => {
    const dataSourcePrepayment = [...this.state.dataSourcePrepayment];

    this.setState({
      dataSourcePrepayment: dataSourcePrepayment.filter(item => item.key !== key)
    });

    setTimeout(() => { //recalculate
      let tempTotal = 0;
      for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
        tempTotal += this.state.dataSourcePrepayment[i].amount;
      }
      this.setState({ prepaymentSelectedAmount: tempTotal });

    }, 1000)

  }

  handleAdd = () => {
    const { count, dataSourcePrepayment, recurringItems, dataSource } = this.state;
    let amount = 0;
    let finalAmount = 0;

    if (dataSourcePrepayment.length === 0) {

      if (this.state.prepaymentTotalAmount > 0) {

        if (this.state.prepaymentTotalAmount >= recurringItems[0].amount ? recurringItems[0].amount : 0) {
          amount = recurringItems[0].amount ? recurringItems[0].amount : 0; finalAmount = amount;
        }
        if (this.state.prepaymentTotalAmountDisplay > 0) { }

      }
    } else {

      for (let a = 0; a < dataSourcePrepayment.length; a++) {
        amount += dataSourcePrepayment[a].amount;

      }
      if (this.state.prepaymentTotalAmount - amount >= recurringItems[0].amount) {
        amount = recurringItems[0].amount;
        finalAmount = amount;
      } else {

        finalAmount = Number(this.state.prepaymentTotalAmount - amount).toFixed(2);
      }
    }

    const newData = {
      key: count,
      chargeId: recurringItems[0].chargeId._id,
      year: new Date().getFullYear(),
      actualAmount: recurringItems[0].amount,
      month: moment().format('MMMM'),
      amount: finalAmount
    };


    this.setState({
      dataSourcePrepayment: [
        ...dataSourcePrepayment,
        newData
      ],
      count: count + 1
    });

    //recalculate total amount
    if (this.state.prepaymentTotalAmount > 0) {

      setTimeout(() => {
        let tempSelectedTotal = 0;

        for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
          tempSelectedTotal += this.state.dataSourcePrepayment[i].actualAmount;
        }

        this.setState({
          prepaymentSelectedAmount: tempSelectedTotal,
          prepaymentTotalAmount: this.state.payAmount - this.state.selectedAmount - tempSelectedTotal
        });

      }, 1000);

    } else {

      setTimeout(() => {
        let tempTotal = 0;
        let tempSelectedTotal = 0;

        for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
          tempTotal += this.state.dataSourcePrepayment[i].amount;
          tempSelectedTotal += this.state.dataSourcePrepayment[i].actualAmount;
        }

        this.setState({
          prepaymentTotalAmount: tempTotal,
          prepaymentSelectedAmount: tempSelectedTotal
        });

      }, 1000);

    }

  }

  handleSave = (row) => {

    if (parseFloat(row.knockOffAmount) > parseFloat(row.appliedAmount - row.knockOffBalance).toFixed(2)) {
      message.error('Amount can\'t greater than remaining charges.');
      return;
    }

    //dataSource
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });

    //dataSelectedrow
    const newData2 = [...this.state.selectedRows];
    const index2 = newData2.findIndex(item => row._id === item._id);
    const item2 = newData2[index2];
    newData2.splice(index2, 1, {
      ...item2,
      ...row
    });

    let amt = 0;
    for (let i = 0; i < newData2.length; i++) {
      amt += parseFloat(newData2[i].knockOffAmount).toFixed(2);
    }

    this.setState({ dataSource: newData, selectedRows: newData2, totalAmount: amt });

  }

  handleSavePrepayment = (row) => {

    if (row.amount > row.actualAmount) {
      message.error('Amount can\'t greater than charge Amount.');
      return;
    }

    const newData = [...this.state.dataSourcePrepayment];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });

    let tempTotal = 0;
    let tempSelectedTotal = 0;
    for (let i = 0; i < newData.length; i++) {

      tempTotal = tempTotal + (parseFloat(newData[i].amount) || 0);
      tempSelectedTotal = + newData[i].actualAmount;
    }

    this.setState({ dataSourcePrepayment: newData, prepaymentTotalAmountDisplay: tempTotal, prepaymentTotalAmount: tempTotal, prepaymentSelectedAmount: tempSelectedTotal });

  }

  getInputStyle = (dataType) => {
    if (dataType === 'amount') {
      return 'number';
    } else if (dataType === 'month') {
      return 'month';
    } else if (dataType === 'chargeId') {
      return 'chargeId';
    } else {
      return 'text';
    }
  }

  renderFooter() {
    return ([<Row key='footer1'>
      <Col offset={11} span={9} style={{
        'textAlign': 'right',
      }}>

        <span className='customBold2'>Total KnockOff :</span>
      </Col>
      <Col span={1}>
        <span></span>
      </Col>
      <Col span={3}>
        <Input key='totalKnockOff' style={{
          color: 'green',
          'textAlign': 'right'
        }} value={this.formatAmt(this.state.totalAmount)} disabled={true} />
      </Col>

    </Row>
    ])
  }

  renderFooterPrepayment() {
    return ([<Row key='prepaymentfooter'>

      <Col offset={11} span={9} style={{
        'textAlign': 'right',
      }}>
        <span className='customBold2'>Total Amount :
        </span>
      </Col>
      <Col span={1} style={{}}>
        <span></span>
      </Col>
      <Col span={3}>
        <Input style={{
          color: 'green',
          'textAlign': 'right'
        }} value={this.formatAmt(this.state.prepaymentTotalAmountDisplay)} disabled={true} />
      </Col>
    </Row>
    ])
  }

  renderFooterPrepayment2() {
    return ([


      <Row key='prepaymentfooter2' className='backgrouGrey'>
        <Col offset={11} span={9} style={{
          'text-align': 'right',
          'margin-right': '10px',
          'margin-top': '10px',
          'margin-bottom': '10px',
        }}>
          <span className='customBold2'>Total Amount : </span>
        </Col>
        <Col span={3}>
          <Input style={{
            color: 'green',
            'marginTop': '10px',
            'marginRight': '5px',
            'marginBottom': '20px',
            'marginLeft': '20px',
          }} value={this.formatAmt(this.state.prepaymentUA)}
            disabled={true} />
        </Col>
      </Row>
    ])
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  _paymentDateChange = (e) => {

    this.setState({
      paymentDate: moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  unAllocatedPrepaymentAmount = (e) => {
    if (!e) {
      this.setState({ prepaymentUA: 0 })
    } else {
      this.setState({ prepaymentUA: e });
    }
  }

  _handlePrepayChargeChange = (e) => {
    let chargeInfo = _.find(this.state.recurringItems, { '_id': e })
    let { prepaymentHist, recurrMonths } = this.state
    // console.log({e, chargeInfo});
    this.props.form.setFieldsValue(
      { prepayAmount: chargeInfo.amount }
    );

    recurrMonths = moment.months()
    prepaymentHist.forEach((i) => {
      // console.log({i},recurrMonths.indexOf(i.month));
      if (recurrMonths.indexOf(i.month) > 0) {
        recurrMonths.splice(recurrMonths.indexOf(i.month), 1)
      }
    })

    this.setState({ recurrMonths })

    // console.log({chargeInfo, prepaymentHist, recurrMonths});
  }

  _addItem = (e) => {
    e.preventDefault()
    let { dataSourcePrepayment } = this.state
    this.props.form.validateFields((err, values) => {
      // console.log({values});
      dataSourcePrepayment.push(values)
      // this.setState({})
    })
  }

  render() {
    const { dataSource, dataSourcePrepayment, selectedRowKeys, loading, selectedRows } = this.state;
    const { getFieldDecorator } = this.props.form;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {

        let tempAmt = 0;
        let newData = [...this.state.dataSource];

        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].appliedAmount - selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount = selectedRows[selectedRows.length - 1].appliedAmount - selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(item => selectedRows[selectedRows.length - 1]._id === item._id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1]
          });
          //end handle latest selected row
        }

        if (dataSourcePrepayment.length === 0) { }

        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys, selectedAmount: tempAmt, dataSource: newData, totalAmount: tempAmt });
      }
    };

    const hasSelected = selectedRows.length > 0;

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({ record, editable: col.editable, dataIndex: col.dataIndex, title: col.title, handleSave: this.handleSave })
      };
    });

    const columnsPrepayment = this.columnsPrepayment.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSavePrepayment,
          recurringItems: this.state.recurringItems,
          invoiceItem: this.state.dataSource
        })
      };
    });

    const dateFormat = 'YYYY/MM/DD';

    const config = {
      initialValue: moment(),
      rules: [
        {
          type: 'object',
          required: true,
          message: 'Please select date!'
        }
      ]
    };

    return (
      <Layout>
        <Spin key='loadingPage' spinning={this.state.loading}>
          <Card title={'Official Receipt'}>
            <Row>
              <Col span='18'> {hasSelected ? <span>Selected {selectedRows.length} items</span> : ''}
                <Table rowSelection={rowSelection} components={components} rowClassName={() => 'editable-row'} bordered dataSource={dataSource} columns={columns} pagination={false} size='small' scroll={{ y: 340 }} rowKey="_id" footer={() => this.renderFooter()} />
                <br />
                <Tabs defaultActiveKey="1">
                  {/* <TabPane tab="Prepayment by Allocation" key="1">

                    <Button onClick={this.handleAdd} type="primary" style={{ marginTop: 5, marginBottom: 10 }}> Add a Prepayment </Button>

                    <Table components={components} rowClassName={() => 'editable-row'} bordered dataSource={dataSourcePrepayment} columns={columnsPrepayment} pagination={false} size='small' footer={() => this.renderFooterPrepayment()}/>

                  </TabPane> */}

                  <TabPane tab="Prepayment by Amount" key="2">
                    <Form.Item {...formItemLayout2} label="Prepayment Amount">
                      {getFieldDecorator('prepaymentAmountUA')(<InputNumber key='prepaymentAmountUA' value={this.state.prepaymentAmountUA} onChange={this.unAllocatedPrepaymentAmount} />)}
                    </Form.Item>

                    {this.renderFooterPrepayment2()}

                  </TabPane>
                  {/* 
                  <TabPane tab='Credit Notes' key='3'>



                  </TabPane> */}

                </Tabs>
                <br />
                <Row key='totaling' className='backgrouGrey'>
                  <Col offset={11} span={9} style={{
                    'textAlign': 'right',
                    'marginRight': '10px',
                    'marginTop': '10px',
                    'marginBottom': '10px',
                  }}>
                    <span className='customBold2'>Total KnockOff + Total Prepayment : </span>
                  </Col>
                  <Col span={3}>
                    <Input style={{
                      color: 'green',
                      'marginTop': '10px',
                      'marginRight': '5px',
                      'marginBottom': '20px',
                      'marginLeft': '20px',
                      'textAlign': 'right',
                    }} value={
                      parseFloat(
                        (this.state.totalAmount ? this.state.totalAmount : 0) +
                        (this.state.prepaymentTotalAmountDisplay ? this.state.prepaymentTotalAmountDisplay : 0) +
                        (this.state.prepaymentUA ? this.state.prepaymentUA : 0)
                      ).toFixed(2)
                    }

                      disabled={true} />
                  </Col>
                </Row>
              </Col>

              <Col span='6'>
                <Form.Item {...formItemLayout} label="Payment Date">
                  {getFieldDecorator('paymentDate', config)(<DatePicker key='paymentDate' onChange={this._paymentDateChange} />)}
                </Form.Item>

                <FormItem {...formItemLayout} label="Payment Amt">
                  {
                    getFieldDecorator('payAmount', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Amount!'
                        }
                      ]
                    })(<Input style={{ 'textAlign': 'right' }} disabled={true} onChange={this.handleAutoSumUpAmt} />)
                  }
                </FormItem>

                <PaymentFormItems
                  paymentDate={this.state.paymentDate}
                  invoiceItems={this.state.selectedRows}
                  propertyunitId={this.props.match.params.id}
                  prepayment={this.state.dataSourcePrepayment}
                  prepaymentTotalAmount={this.state.prepaymentTotalAmount}
                  totalAmount={this.state.totalAmount}
                  payAmount={this.state.payAmount}
                  prepaymentUA={this.state.prepaymentUA} />

              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    )
  }

}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexKnockOffByPropertyunit)));

import React, { Component } from 'react';
import { Row, Col, Card, Divider, Table, Form, Spin } from 'antd';
import client from '../../feathers';
import Info from "../common/info";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchInvoices,
  pushInvoices,
  removeInvoices,
  updateMode,
  updateActiveInvoices,
  updateInvoices
} from '../../actions/actions-invoices';

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  n = n ? n : 0;
  let amt = currency ? currency : '' + n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
}

class InvoicesPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      dataSource: [],
      unit: '',
      visible: false,
      setting: [],
      selectedRowKeys: [], // Check here to configure the default column,
      jmb: [],
      customer: [],
      owner: [],
      hasError: false,
      mailingAddress: '',
      loading: false
    }
  }

  componentWillMount() {

    this.setState({ loading: true });

    client.authenticate()
      .then(() => {
        return client.service('invoices').find({
          query: {
            _id: this.props.invoiceId,
            $populate: 'propertyunitId'
          }
        })
      })
      .then((res) => {
        this.setState({
          invoices: res.data[0],
          customer: res.data[0].customerType === 'PROPERTYUNIT' ? res.data[0].propertyunitId : res.data[0].customerId,
          owner: res.data[0].customerType === 'PROPERTYUNIT' ? res.data[0].propertyunitId.owner : res.data[0].customerId,
          loading: false
        });
        this.props.form.setFieldsValue({
          amount: formatCurr(res.data.totalAmt),
        });

        console.log('lala',this.state.customer,this.state.owber)
        //get invoice items
        return client.service('invoice-items').find({
          query: {
            $populate: 'chargeId',
            invoiceId: this.props.invoiceId,
            $sort: {
              docId: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getJmbInfo();
    this.getTnc();
  }

  getJmbInfo() {
    let companyId = this.props.commons.selectedCompany._id
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: companyId,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          jmb: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  getTnc() {
    let companyId = this.props.commons.selectedCompany._id
    client.authenticate()
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: companyId
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  componentDidMount() {

    client.service('invoices').on('patched', (invoice) => {
      if (invoice._id === this.state.invoices._id) {
        this.setState({ invoices: invoice });
      }
    })

  }

  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }

  _Field_OutStanding(date) {
    // if (this.state.invoices.status == 'PAID') {
    //   return ([<Info label='Previous Outstanding' labelSpan={14} value='0.00' />])

    // }
    // if (this.state.invoices.invoiceType !== 'OTHERS') {
    //   return ([<Info label='Previous Outstanding' labelSpan={14} value={formatCurr(this.state.invoices.previousOutstanding)} valueSpan={10} />])
    // }
    return ([<Info type="amount" label='Previous Outstanding' labelSpan={14} value={formatCurr(this.state.invoices.previousOutstanding)} valueSpan={10} />])
  }

  _Field_TotalBillAmount(date) {
    // if (this.state.invoices.status == 'PAID') {
    //   return ([<Info label='Previous Outstanding' labelSpan={14} value='0.00' />])

    // }
    // if (this.state.invoices.invoiceType !== 'OTHERS') {
    //   return ([<Info label='Outstanding' labelSpan={14} value={formatCurr(this.state.invoices.previousOutstanding)} valueSpan={10} />])
    // }
    return ([<Info type="amount" label='Outstanding' labelSpan={14} value={formatCurr(this.state.invoices.previousOutstanding > 0 ? this.state.invoices.previousOutstanding : 0)} valueSpan={10} />])
  }

  _Field_NetAmount(date) {
    // if (this.state.invoices.status == 'PAID') {
    //   return ([<Info label='Net Amount' labelSpan={14} valuePos={'right'} value={formatCurr(this.state.invoices.totalAmt)} valueSpan={10} />
    //   ])

    // }
    // if (this.state.invoices.invoiceType !== 'OTHERS') {
    //   return ([<Info label='Net Amount' labelSpan={14} valuePos={'right'} value={formatCurr(this.state.invoices.totalAmt + (this.state.invoices.previousOutstanding))} valueSpan={10} />])
    // }
    return ([<Info type="amount" bold label='Net Amount' labelSpan={14} valuePos={'right'} value={formatCurr(this.state.invoices.totalAmt + (this.state.invoices.previousOutstanding))} valueSpan={10} />])
  }

  _renderDescription(v) {
    let data = v.split("<br />")
    return (
      data.map(i => (
        <span style={{}}>{i} <br /></span>
      ))
    )
  }

  _renderTable = () => {

    const { jmb, dataSource } = this.state;
    let table = [];

    const columns = [
      {
        title: 'Doc ID',
        dataIndex: 'docId',
        key: 'docId',
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (v) => this._renderDescription(v)
      }, {
        title: 'Effective Date',
        dataIndex: 'effectiveDate',
        key: 'effectiveDate',
        render: (text, record) => (
          <span>{this.convertDate(text)}</span>
        ),
      }, {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (text, record) => (
          <span>{this.convertDate(text)}</span>
        ),
      }, {
        title: (
          <span style={{ float: 'right' }} >Amount</span>
        ),
        dataIndex: 'appliedAmount',
        key: 'appliedAmount',
        render: (text, record) => (
          <span style={{ float: 'right' }} >{formatCurr(text)}</span>
        ),
      }];

    let remain = dataSource.length % 10;
    let totalPage = parseInt(dataSource.length / 10) + (remain > 0 ? 1 : 0);

    for (let a = 0; a < totalPage; a++) {

      table.push([
        <div>
          <Card>
            <Row>
              <Col span={3}>
                <img width='100%' src={jmb ? jmb.headerFile : ''} />
              </Col>
              <Col span={14} offset={1} >
                <p style={{ fontWeight: 'bolder', color: "#000" }}>{jmb.managementName} ({jmb ? jmb.registrationNo : ''})</p>
                <p style={{ marginTop: -15 }}><span>{jmb ? jmb.headerLine1 : ''} </span></p>
                <p style={{ marginTop: -15 }}><span>{jmb ? jmb.headerLine2 : ''}</span></p>
                <p style={{ marginTop: -15 }}><span>{jmb ? jmb.headerLine3 : ''}</span></p>
                <p style={{ marginTop: -15 }}><span>{jmb ? jmb.headerLine4 : ''}</span></p>
                <p style={{ marginTop: -15 }}><span>{jmb ? 'Mangement Contact: ' + jmb.contactNo : ''}</span></p>
                <p style={{ marginTop: -15 }}><span>{jmb ? 'EMAIL: ' + jmb.email : ''}</span></p>
              </Col>
              <Col span={6}>
                <h4>{this.state.invoices.isDebitNote?'DEBIT NOTE':'TAX INVOICE/STATEMENT'}</h4>
                <Info label='Invoice No.' labelSpan={10} value={this.state.invoices.invoiceNo} valueSpan={14} />
                <Info label='Date' labelSpan={10} value={this.convertDate(this.state.invoices.invoiceDate)} valueSpan={14} />
                <Info label='Status' labelSpan={10} value={this.state.invoices.status === 'PAID' ? <span style={{ color: 'green' }} >{this.state.invoices.status}</span> : <span style={{ color: 'red' }}>{this.state.invoices.status}</span>} valueSpan={14} />
                {/*<Info label='Outstanding' labelSpan={10}  value={this.formatAmt(this.state.invoices.previousOutstanding)} valueSpan={14} />*/}

                {this._Field_OutStanding()}

              </Col>

            </Row>
            <Row>
              <Col span={18}>
                <Info label='Issued To' labelSpan={4} bold value={this.state.customer.referenceId} valueSpan={20} />
                <Info label='Attn' labelSpan={4} bold value={this.state.owner ? this.state.owner.name : ''} valueSpan={20} />
                {/* <Info label='Mailing Address' labelSpan={4} value={this.formatAddress(this.state.owner?this.state.owner.mailingAddress:'')} valueSpan={4} /> */}
                <Info label='Mailing Address' bold labelSpan={4} value={this.state.owner ? this.state.owner.mailingAddress : ''} valueSpan={20} />
              </Col>
              <Col span={6}>
                <p>*OUTSTANDING B/F AMOUNT MIGHT BE VARY AS PENDING ADD VALUE MANAGEMENT SERVICES FINAL CONFIRMATION</p>
              </Col>
            </Row>

            <Divider dashed >Detail</Divider>
            <div style={{ height: 'auto', marginBottom: '20px' }}>
              <Table
                pagination={{ current: a + 1, pageSize: 10, simple: true }}
                dataSource={dataSource}
                columns={columns}
                size='small'
                rowKey='_id'
              />
            </div>
            <Row className="noBorder">
              <Col span={6}>
                <Info label='Remark' labelSpan={6} value={this.state.invoices.remark} valueSpan={18} />
              </Col>
              <Col span={8} offset={10}>
                <Info type="amount" label='Current Bill Amount' labelSpan={14} valuePos={'right'} value={formatCurr(this.state.invoices.totalAmt)} valueSpan={10} />
                <Info type="amount" label='Total Prepayment Amount' labelSpan={14} valuePos={'right'} value={formatCurr(this.state.invoices.previousOutstanding < 0 ? this.state.invoices.previousOutstanding : 0)} valueSpan={10} />
                {this._Field_TotalBillAmount()}
                {this._Field_NetAmount()}
              </Col>

              <div className="invoiceFooter">
                <div className="ql-display-container">
                  <div className="ql-display-editor" dangerouslySetInnerHTML={{ __html: this.state.setting ? this.state.setting.invoiceTnc : '' }} />
                  {/*  <div style={{'text-align': 'right'}}>© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div> */}
                  {/* <div style={{ 'textalign': 'right' }} >© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div> */}
                </div>
              </div>

            </Row>
          </Card>
        </div>
        ,
        <div className='pageBreak' />
      ])
    }
    return table;
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoice: state.invoice,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoices: fetchInvoices,
    pushInvoices: pushInvoices,
    removeInvoices: removeInvoices,
    updateActiveInvoices: updateActiveInvoices,
    updateInvoices: updateInvoices,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesPrint));
